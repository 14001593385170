<template>
  <v-app>
    <header>
      <img class="logo-img" src="./assets/logo.svg">
    </header>
    <main>
      <div class="hero">
        <img src="./assets/hero.png" alt="ヒーローイメージ" />
      </div>
      <section id="service">
        <h2>サービス情報</h2>
        <div class="service-item">
          <a href="https://ainou.space/" target="_blank">
            <img
                :src="Image1"
                alt="AINOU Image"
                style="max-width: 700px; display: block; margin: 1rem 0;"
                class="logo-img2"
            />
          </a>
          <p>AIチャットボットAINOU</p>
        </div>
      </section>
      <section id="company">
        <h2>会社情報</h2>
          <v-table dense>
            <tbody>
              <tr v-for="(item, i) in items" :key="i">
                <td>{{ item.name }}</td>
                <td>{{ item.detail }}</td>
              </tr>
            </tbody>
          </v-table>
      </section>
    </main>
    <footer>
      <p>Copyright © enigo, Inc. All Rights Reserved.</p>
    </footer>
  </v-app>
</template>

<script>

export default {
  methods: {
    scrollTo(sectionId) {
      this.$nextTick(() => {
        const element = document.querySelector(`#${sectionId}`);
        if (element) {
          console.log(element)
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  },
  data: () => ({
    showMenu: false,
    Image1: require('@/assets/ainou.png'),
    items: [
      { name: '会社名', detail: '合同会社エニゴ' },
      { name: '代表', detail: '小泉駿大' },
      { name: '設立', detail: '2023年6月' },
      { name: '所在地', detail: '〒150-0043 東京都渋谷区道玄坂1丁目10番8号 渋谷道玄坂東急ビル2F-C' },
      { name: '代表メールアドレス', detail: 'info@enigo.contact' },
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    text: ''
  })
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 1px;
  background-color: #ffffff;
  color: #ffffff;
}

.logo {
  font-size: 24px;
}

section {
  padding: 20px;
}

section {
  padding: 20px;
  /* 追加スタイル */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

footer {
  padding: 20px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  bottom: 0;
  width: 100%;
}

.hero {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.hero img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-img {
  max-width: 300px;
  max-height: 120px;
}

.logo-img2 {
  max-width: 500px;
  max-height: 120px;
}

.custom-textarea {
  width: 70%;
}


/* スマートフォンのデザイン調整 */
@media only screen and (max-width: 768px) {

}


</style>
